"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FONTFAMILY = exports.COLORS = void 0;
// typescript import
// typescript definition
// definition
var COLORS = {
  black: '000000',
  white: 'ffffff',
  red: 'ff0000',
  orange: 'ffa500',
  yellow: 'ffff00',
  green: '008000',
  blue: '0000ff',
  purple: '800080'
};
exports.COLORS = COLORS;
var FONTFAMILY = ['微軟正黑體', '標楷體', 'PingFang TC', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier', 'Courier New', 'Franklin Gothic', 'Georgia', 'Helvetica', 'Impace', 'Lucida Grande', 'Lucida Scans', 'Monospace', 'Tahoma', 'Times', 'Times New Roman', 'Verdana'];
exports.FONTFAMILY = FONTFAMILY;